<template>
  <div class="auth">
    <div v-if="user.confirm_token">
      <form class="form" method="post"></form>
    </div>
    <div v-else>
      <div class="alert alert-info">{{ $t("auth.tokenAlert") }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "auth-confirm",
  data() {
    return {
      user: {
        confirm_token: "",
      },
    };
  },
  methods: {
    confirm_user() {
      axios.get(process.env.VUE_APP_BASE_API + "/auth/confirmation?confirmation_token=" + this.user.confirm_token, { headers: {} }).then((response) => {
        console.log(response);
        this.$notify({
          group: "notification",
          type: "success",
          text: this.$t("auth.confirmNote"),
        });
        this.$router.push("/auth/sign_in");
      });
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.user.confirm_token = this.$route.query.token;
      this.confirm_user();
    }
  },
};
</script>
